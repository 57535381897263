

























import { Component, Vue } from 'vue-property-decorator';
import { GeneralData, GENERAL_DATA_DESCRIPTOR, LoaderInstance } from '../loader';

@Component
export default class Navbar extends Vue {
	title?: string;
	logo?: string;
	private navbarHeight!: number;
	
	created() {
		LoaderInstance.getData(GENERAL_DATA_DESCRIPTOR, this.onDataLoaded);
	}

	mounted() {
		this.navbarHeight = document.getElementById('navbar')!.getBoundingClientRect().height;
	}

	onDataLoaded(data: GeneralData) {
		this.title = data.title;
		this.logo = data.logo.url;
	}
	
	scrollTo(elementId?: string) {
		let y = 0;

		// If we got elementId try to find it and scroll to it
		if (elementId) {
			const element = document.getElementById(elementId);

			if (element)
				y = Math.ceil(element.getBoundingClientRect().top + window.pageYOffset - this.navbarHeight);
		}
		
		window.scrollTo({ top: y, behavior: "smooth" });
	}

	getNavbarHeight(): number {
		const element = document.getElementById('navbar');
		return element ? element.scrollHeight : 0;
	}
}
