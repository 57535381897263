














import { Component, Vue } from 'vue-property-decorator';
import { TextEntity, CONTACTS_DATA_DESCRIPTOR, LoaderInstance, ContactsSectionData } from '../loader';
import MarkdownIt from 'markdown-it';

@Component
export default class Contacts extends Vue {
	public title = '';
	public contacts = new Array<TextEntity>();

	created() {
		LoaderInstance.getData(CONTACTS_DATA_DESCRIPTOR, this.onDataLoaded);
	}

	onDataLoaded(contactsData: ContactsSectionData) {
		if (!contactsData)
			return;

		const md = new MarkdownIt({
			html: true,
			linkify: true,
			typographer: true,
			breaks: true
		});


		this.title = contactsData.title;
		this.contacts = contactsData.contacts;

		for (const contacts of this.contacts)
			contacts.text = md.render(contacts.text);
	}
}
