


















import { Component, Vue } from 'vue-property-decorator';
import { TextEntity, SERVICES_DATA_DESCRIPTOR, LoaderInstance, ServicesSectionData } from '../loader';
import MarkdownIt from 'markdown-it';

@Component
export default class Services extends Vue {
	public isLoading = true;
	
	public title = '';
	public services = new Array<TextEntity>();

	created() {
		LoaderInstance.getData(SERVICES_DATA_DESCRIPTOR, this.onDataLoaded);
	}

	onDataLoaded(data: ServicesSectionData) {
		if (!data)
			return;

		const md = new MarkdownIt({
			html: true,
			linkify: true,
			typographer: true,
			breaks: true
		});

		this.title = data.title;
		this.services = data.services;

		for (const service of this.services)
			service.text = md.render(service.text);
	}
}
