



















import { Component, Vue } from 'vue-property-decorator';
import Error from './components/Error.vue';
import Navbar from './components/Navbar.vue';
import HeaderImage from './components/HeaderImage.vue';
import AboutMe from './components/AboutMe.vue';
import Services from './components/Services.vue';
import Contacts from './components/Contacts.vue';
import Footer from './components/Footer.vue';
import { GeneralData, GENERAL_DATA_DESCRIPTOR, LoaderInstance } from './loader';

@Component({
	components: {
		Error,
		Navbar,
		HeaderImage,
		AboutMe,
		Services,
		Contacts,
		Footer
	}
})
export default class App extends Vue {
	loader = LoaderInstance;

	created() {
		document.title = 'Loading...';
		this.loader.load();

		this.loader.getData(GENERAL_DATA_DESCRIPTOR, this.onTitleLoaded);
	}

	onTitleLoaded(data: GeneralData) {
		document.title = data.title;
	}
}
