









import { Component, Vue } from 'vue-property-decorator';
import { GeneralData, GENERAL_DATA_DESCRIPTOR, LoaderInstance } from '../loader';

@Component
export default class Copyright extends Vue {
	public title = '';

	created() {
		LoaderInstance.getData(GENERAL_DATA_DESCRIPTOR, this.onTitleLoaded);
	}

	onTitleLoaded(data: GeneralData) {
		this.title = data.title;
	}
}
