






import { Component, Vue } from 'vue-property-decorator';
import { GeneralData, GENERAL_DATA_DESCRIPTOR, LoaderInstance } from '../loader';

@Component
export default class HeaderImage extends Vue {
	image?: string;

	created() {
		LoaderInstance.getData(GENERAL_DATA_DESCRIPTOR, this.onDataLoaded);
	}

	onDataLoaded(data: GeneralData) {
		this.image = data.image.url;
	}
}
