


























import { Component, Vue } from 'vue-property-decorator';
import { AboutMePopupData, AboutMeSectionData, ABOUT_ME_DATA_DESCRIPTOR, LoaderInstance } from '../loader';
import MarkdownIt from 'markdown-it';

@Component({})
export default class AboutMe extends Vue {
	md!: MarkdownIt;

	text = '';

	photoUrl = 'https://via.placeholder.com/1920x1080';
	photoWidth = 0;

	popups = new Array<AboutMePopupData>();

	public activePopup!: AboutMePopupData;
	public isPopupActive = false;

	created() {
		this.md = new MarkdownIt({
			html: true,
			linkify: true,
			typographer: true,
			breaks: true
		});
		
		LoaderInstance.getData(ABOUT_ME_DATA_DESCRIPTOR, this.onDataLoaded);

		this.activePopup = { label: '', body: '' }
	}

	onDataLoaded(data: AboutMeSectionData) {
		if (!data)
			return;

		this.text = this.md.render(data.body);

		this.photoUrl = data.photo.url;
		this.photoWidth = data.photoWidth;

		this.popups = data.popups;

		for (const popupData of this.popups)
			popupData.body = this.md.render(popupData.body);
	}

	activatePopup(popupData: AboutMePopupData) {
		this.activePopup = popupData;
		this.isPopupActive = true;
	}

	cancelPopup() {
		this.isPopupActive = false;
	}
}
