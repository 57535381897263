export interface StrapiImage {
	name: string;
	url: string;
}

export interface TextEntity {
	id: string;
	text: string;
}

export interface GeneralData {
	title: string;
	logo: StrapiImage;
	image: StrapiImage;
}

export interface AboutMeSectionData {
	photo: StrapiImage;
	photoWidth: number;
	body: string;
	popups: AboutMePopupData[];
}

export interface AboutMePopupData {
	label: string;
	body: string;
}

export interface ServicesSectionData {
	title: string;
	services: TextEntity[];
}

export interface ContactsSectionData {
	title: string;
	contacts: TextEntity[];
}

/**
 * Helps our loader to know what kind of data we need and how do we get it.
 */
export interface DataDescriptor<DataType> {
	id: string;
	url: string;

	// In case data is required and we can't get it, we can thrown an error
	required: boolean;
}

export const GENERAL_DATA_DESCRIPTOR: DataDescriptor<GeneralData> = {
	id: 'general',
	url: '/general',
	required: true
};

export const ABOUT_ME_DATA_DESCRIPTOR: DataDescriptor<AboutMeSectionData> = {
	id: 'about-me-section',
	url: '/about-me-section',
	required: true
};

export const SERVICES_DATA_DESCRIPTOR: DataDescriptor<ServicesSectionData> = {
	id: 'services-section',
	url: '/services-section',
	required: false
};

export const CONTACTS_DATA_DESCRIPTOR: DataDescriptor<ContactsSectionData> = {
	id: 'contacts-section',
	url: '/contacts-section',
	required: false
};

interface OnDataCallback<DataType> {
	(data: DataType): void;
}

/**
 * Responsible for loading all the data we need.
 */
class Loader {
	private data: Map<string, unknown>;
	private dataCallbacks: Map<string, Array<OnDataCallback<any>>>;

	public loadingList: Array<string>;

	public error = false;

	constructor() {
		this.data = new Map<string, unknown>();

		this.loadingList = new Array<string>();

		this.dataCallbacks = new Map<string, Array<OnDataCallback<any>>>();
	}

	get isLoading() {
		return this.loadingList.length > 0;
	}

	load() {
		this.loadData(GENERAL_DATA_DESCRIPTOR);
		this.loadData(ABOUT_ME_DATA_DESCRIPTOR);
		this.loadData(SERVICES_DATA_DESCRIPTOR);
		this.loadData(CONTACTS_DATA_DESCRIPTOR);
	}

	private loadData<T>(dataDescriptor: DataDescriptor<T>) {
		const instance = this;

		this.loadingList.push(dataDescriptor.id);

		fetch(dataDescriptor.url)
			.then(response => this.checkForError.bind(instance)(dataDescriptor, response))
			.then(response => response.json())
			.then(data => this.onData.bind(instance)(dataDescriptor, data))
			.then(() => this.invokeOnDataCallbacks.bind(instance)(dataDescriptor.id));
	}

	private checkForError(dataDescriptor: DataDescriptor<any>, response: Response): Response {
		if (!response.ok) {
			if (dataDescriptor.required)
				this.error = true;
				
			this.loadinglistRemove(dataDescriptor);
		}

		return response;
	}

	private onData<T>(dataDescriptor: DataDescriptor<T>, data: T) {
		this.data.set(dataDescriptor.id, data);
		this.loadinglistRemove(dataDescriptor);
	}

	private invokeOnDataCallbacks(dataId: string) {
		if (!this.dataCallbacks.has(dataId))
			return;

		// @ts-ignore
		for (const callback of this.dataCallbacks.get(dataId))
			callback(this.data.get(dataId));
	}

	/**
	 * 
	 * @param dataDescriptor Object which describes data you're looking for.
	 * @param callback Data may not be loaded at the moment you invoke this method, so you may pass a function - callback which will invoked once we got it.
	 */
	public getData<T>(dataDescriptor: DataDescriptor<T>, callback?: OnDataCallback<T>): T|undefined {
		if (callback) {
			// If we already have the data we need we can invoke callback with required data instantly
			if (this.data.has(dataDescriptor.id))
				callback(this.data.get(dataDescriptor.id) as T);

			if (!this.dataCallbacks.has(dataDescriptor.id))
				this.dataCallbacks.set(dataDescriptor.id, new Array<OnDataCallback<any>>());

			this.dataCallbacks.get(dataDescriptor.id)?.push(callback);

			return undefined;
		}

		return this.data.get(dataDescriptor.id) as T;
	}

	private loadinglistRemove(dataDescriptor: DataDescriptor<any>) {
		const index = this.loadingList.indexOf(dataDescriptor.id);

		if (index >= 0)
			this.loadingList.splice(index, 1);
	}
}

export const LoaderInstance = new Loader();